import Component from '../classes/Component'

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        this.updateSelectPlaceholderClass(this.element)

        this.element.addEventListener('change', this.updateSelectPlaceholderClass)
    }

    updateSelectPlaceholderClass (ev) {
        let el

        if (ev.target) {
            el = ev.target
        } else {
            el = ev
        }

        var opt = el.options[el.selectedIndex]

        if(opt.classList.contains('has-placeholder')) {
            this.element.classList.add('has-placeholder')
        } else {
            this.element.classList.remove('has-placeholder')
        }
    }
}
