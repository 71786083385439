<template>
    <div class="c-search-result">
        <div v-show="localStorage.length == 0">
            <div class="o-layout">
                <div class="o-layout__item u-12/12 u-6/12@desktop u-push-3/12@desktop">
                    <h3 class="u-h3 u-text-center" id="nofavoritetitle"></h3>
                    <p class="u-text-center" id="nofavoritesdescription"></p>
                </div>
            </div>
        </div>
        <app-tab-list ref="tablist" type="bookmarks" v-show="localStorage.length != 0" v-on:bookmark-removed="onBookmarkRemoved"></app-tab-list>
    </div>
</template>

<script>
import TabList from './TabList.vue';

export default {

    components: {
        'app-tab-list': TabList,
    },

    data() {
        return {
            localStorage: [],
            searchFilter: [],
            searchResults: [],
            total: 0
        }
    },

    props: {
        search: "",
    },

    methods: {
        searchSuccess(data) {
            if(!window.transformeSearchResult) {
                return;
            }

            let transformedSearchResult = window.transformeSearchResult(data);

            if(transformedSearchResult != null) {
                //this.total = this.getSearchTotal(transformedSearchResult.items);
                this.total = transformedSearchResult.totalItems;
                this.searchResults = transformedSearchResult.items;
                if(this.$refs.tablist){
                    this.$refs.tablist.updateTabs(this.searchResults);
                }
            } else {
                this.searchResults = [];
                this.total = 0;
            }
        },
        searchError(data) {
            alert('Erreur dans la recherche, veuillez recommencer.');
        },
        setSearchFilter(filter) {
            this.searchFilter = filter;
        },
        research() {
            window.activeTab = null;
            window.research(this.searchFilter, this.searchSuccess, this.searchError);
        },
        getSearchTotal(data) {
            let total = 0;
            data.forEach((item) => {
                total = total + item.content.length;
            })
            return total;
        },
        onBookmarkRemoved() {
            this.localStorage = JSON.parse(localStorage.getItem('bookmarks'));
            this.setSearchFilter(this.localStorage);
            this.research();
        }
    },
    mounted() {
        if(localStorage.getItem('bookmarks')) {
            this.localStorage = JSON.parse(localStorage.getItem('bookmarks'));
            this.setSearchFilter(this.localStorage);
            this.research();
        }
    }
}
</script>
