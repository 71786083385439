<template>
    <div class="c-search-result">
        <h1 v-if="this.total != 0">Résultats de recherche</h1>
        <h1 v-else>Aucun résultat</h1>
        <h3 class="u-color-teal-blue">{{ this.total }} résultats de recherche pour « {{ this.searchFilter }} »</h3>
        <app-tab-list ref="tablist" v-show="this.total != 0"></app-tab-list>
    </div>
</template>
<script>
import TabList from './TabList.vue';

export default {
    components: {
        'app-tab-list': TabList,
    },
    data() {
        return {
            searchFilter: window.searchFilter,
            searchResults: [],
            total: 0,
            isFirstLoad : true
        }
    },
    props: {
        search: ""
    },
    methods: {
        searchSuccess(data) {
            if(!window.transformeSearchResult) {
                return
            }
            let transformedSearchResult = window.transformeSearchResult(data);
            // console.log('data :');
            // console.log(data);
            // console.log('transformedSearchResult :');
            // console.log(transformedSearchResult);

            if(transformedSearchResult != null) {
                this.total = transformedSearchResult.totalItems;
                this.searchResults = transformedSearchResult.items;
                if(this.$refs.tablist){
                    this.$refs.tablist.updateTabs(this.searchResults);
                }
            } else {
                this.searchResults = [];
                this.total = 0;
            }
        },
        searchError(data) {
            alert('Erreur dans la recherche, veuillez recommencer.')
        },
        setSearchFilter(filter) {
            this.searchFilter = filter
        },
        research(pageIndex, itemPerPage) {
            window.research(this.searchFilter, this.searchSuccess, this.searchError, pageIndex || 0, itemPerPage || 21)
        },
        getSearchTotal(data) {
            let total = 0
            data.forEach((item) => {
                total = total + item.content.length
            })
            return total;
        }
    },
    mounted() {
        if(this.search != "") {
            this.setSearchFilter(this.search)
            this.research()
        }

        document.querySelector('#search-form').addEventListener('submit', ev => {
            ev.preventDefault()
            this.setSearchFilter(document.querySelector('.c-search__input').value)
            this.research()
        });
    }
}
</script>
