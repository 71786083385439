import AutoBind from 'auto-bind';
import EventEmitter from 'events';

let bookmarks;
let store;
let navBtn;
let navBtnCounter;

export default class extends EventEmitter {

    constructor () {
        super();

        AutoBind(this);

        this.init();
    }

    init () {
        // Register nav button icon
        this.navBtn = document.querySelector('.c-bookmarks-btn');
        this.navBtnCounter = this.navBtn.querySelector('.c-bookmarks-btn__count');

        // Get stored datas
        if (localStorage.getItem('bookmarks')) {
            this.store = JSON.parse(localStorage.getItem('bookmarks'));
        } else {
            this.store = [];
            // this.store = [
            //     { "contentType": "contentType1", "content": ["2", "3"] },
            //     { "contentType": "contentType2", "content": ["4", "5", "6" ] }
            // ]
        }

        // Cards to handle in the current page
        this.bookmarks = Array.from(document.querySelectorAll('[data-bookmark-content-id]'));

        this.update();

        // Click event listener on bookmark's icon
        this.bookmarks.forEach((item) => {
            if(!item.classList.contains('c-button-bookmark--close')) {
                item.addEventListener('click', ev => {
                    ev.stopPropagation();
                    ev.preventDefault();

                    // Search in localStorage object if element exists
                    let found = false;
                    for (let i = 0; i < this.store.length; i++) {
                        for (let j = 0; j < this.store[i].content.length; j++) {
                            if (item.dataset.bookmarkContentId == this.store[i].content[j]) {
                                this.remove(item);
                                item.blur();
                                found = true;
                                return;
                            }
                        }
                    }

                    if (found == false) {
                        this.add(item);
                    }
                })
            }else{
                item.addEventListener('click', ev => {
                    ev.stopPropagation();
                    ev.preventDefault();
                })
            }
        })
    }

    add (item) {
        let contentType = item.dataset.bookmarkContentType;
        let contentId = item.dataset.bookmarkContentId;

        // Check if a corresponding content type exists
        if(this.store.length > 0) {
            for (let i = 0; i < this.store.length; i++) {
                if (contentType == this.store[i].contentType) { // Exists
                    this.store[i].content.push(contentId);
                    break;
                }

                if (i+1 >= this.store.length) { // Doesn't exists
                    this.store.push({ contentType: contentType, content: [contentId] });
                    break;
                }
            }
        } else {
            this.store.push({ contentType: contentType, content: [contentId] });
        }


        // Update localStorage
        localStorage.setItem('bookmarks', JSON.stringify(this.store));

        // Update page's items
        this.update();
    }

    remove (item) {
        let contentType = item.dataset.bookmarkContentType;
        let contentId = item.dataset.bookmarkContentId;

        // Check if a corresponding content type exists
        for (let i = 0; i < this.store.length; i++) {
            if (contentType == this.store[i].contentType) { // Exists
                let index = this.store[i].content.indexOf(contentId);
                this.store[i].content.splice(index, 1);

                if (this.store[i].content.length == 0) {
                    this.store.splice(i, 1);
                }

                break;
            }
        }

        // Update localStorage
        localStorage.setItem('bookmarks', JSON.stringify(this.store));

        // Update page's items
        this.update()
    }

    update () {
        this.bookmarks.forEach((item) => {
            let itemContentId = item.dataset.bookmarkContentId;

            let found = false;
            for (let i = 0; i < this.store.length; i++) {
                for (let j = 0; j < this.store[i].content.length; j++) {
                    if (itemContentId == this.store[i].content[j]) {
                        item.classList.add('is-active');
                        found = true;
                    }
                }
            }

            if(found == false) {
                item.classList.remove('is-active');
            }
        })

        // Upadte counter in navigation
        let count = 0;
        for (let i = 0; i < this.store.length; i++) {
            for (let j = 0; j < this.store[i].content.length; j++) {
                count++;
            }
        }

        if (count == 0) {
            this.navBtn.classList.remove('has-bookmarks');
            this.navBtnCounter.innerHTML = "";
        } else {
            this.navBtn.classList.add('has-bookmarks');
            this.navBtnCounter.innerHTML = count;
        }
    }
}
