import AutoBind from 'auto-bind'
import EventEmitter from 'events'

let wrapper
let header
let offset

export default class StickyHeader extends EventEmitter {

    constructor (wrapper, header) {
        super()

        AutoBind(this)

        this.wrapper = wrapper

        this.header = header

        this.init()
    }

    init() {
        this.offset = this.header.offsetTop

        this.toggleSticky()

        window.addEventListener('scroll', this.toggleSticky)
        window.addEventListener('resize', this.toggleSticky)
    }

    toggleSticky() {
        if (window.pageYOffset >= this.offset) {
            this.wrapper.classList.add('has-sticky-header')
            document.querySelector('.c-wrapper').style.paddingTop = `${this.header.offsetHeight}px`
        } else {
            this.wrapper.classList.remove('has-sticky-header')
            document.querySelector('.c-wrapper').style.paddingTop = '0'
        }
    }
}
