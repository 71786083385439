<template>
  <div class="c-accordion">
      <div class="o-layout">
          <div class="o-layout__item u-12/12 u-text-center">
            <div class="c-accordion-title">
                <h2 class="u-h2" v-if="title">{{ title }}</h2>
            </div>
          </div>
      </div>
      <slot name="content" />
  </div>
</template>

<script>
    export default {
        data: () => ({
            items: []
        }),

        props: {

            /**
             * Default Accordion title
             */
            title: {
                type: String,
                default: ""
            },

            /**
             * Can be collapsed multiple times
             *
             * Default at false
             */
            multiple: {
                type: Boolean,
                default: false
            }
        },

        // provide methods for the child items AccordionItems
        provide() {
            return {
                register: this.register,
                close: this.close
            }
        },

        methods: {

            /**
             * Close all registered accordion items
             * expect current selected
             */
            close(uid) {

                // disable if multiple is set to false
                if ( this.multiple ) {
                    return;
                }

                if ( this.items === [] ) {
                    return;
                }

                // filter out all items expect current selected
                this.items
                    .filter(item => item._uid != uid )
                    .forEach( item => {
                        item.collapse();
                    })
            },

            /**
             * Register an accordion item
             * to the parent element
             */
            register(item) {
                this.items.push(item);
            },
        }
    }
</script>
