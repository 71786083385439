<template>
  <div class="c-accordion-item">
        <button
            class="c-accordion-button"
            @click="toggleAccordion()"
            :aria-expanded="isOpen"
            :aria-controls="`collapse${_uid}`"
        >
        <div class="c-accordion-item__title">
            <h1>{{ title }}</h1>
        </div>

        <div class="c-button-arrow" v-bind:class="{ 'c-button-arrow--down': isOpen }">
            <svg class="c-icon">
                <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
            </svg>
        </div>
    </button>

    <div
        v-bind:class="{ 'c-accordion-item__content-wrapper--hide': !isOpen }"
        :id="`collapse${_uid}`"
        class="c-accordion-item__content-wrapper"
        ref="wrapper"
    >
        <div class="c-accordion-item__content" ref="content">
            <slot name="content">
                Default content of the Accordion Item component
            </slot>
    </div>
    </div>
  </div>
</template>

<script>
export default {
    // inject methods from parent element Accordion
    inject: ['register', 'close'],

    beforeMount() {
        this.register(this);
    },

    mounted() {
        this.$refs.wrapper.maxHeight = this.$refs.content.clientHeight;
    },

    data() {
        return {
            isOpen: false
        };
    },

    props: {

        /**
         * Default Accordion Item title
         */
        title: {
            type: String,
            default: 'My Accordion Title'
        }
    },

    methods: {
        toggleAccordion() {

            // toggle current element
            this.isOpen = !this.isOpen;

            // callback this uid to the parent element: Accordion
            this.close(this._uid);

            this.$el.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
        },
        collapse() {
            this.isOpen = false;
        }
    },
}
</script>
