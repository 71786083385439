import AutoBind from 'auto-bind'
import ShareUrl from 'share-url'

let el
let url
let shareItems

export default class ShareLocation {

    constructor (element) {
        AutoBind(this)

        if(element) {
            this.el = element
            this.url = window.location.href
            this.init()
        }
    }

    init() {
        this.shareItems = Array.from(this.el.querySelectorAll('[data-share-item]'))

        this.shareItems.forEach((item) => {
            item.addEventListener('click', this.share)
        })
    }

    share(ev) {
        ev.preventDefault()

        let shareUrl

        switch (ev.currentTarget.dataset.shareItem) {
            case 'facebook':
                this.shareUrl = ShareUrl.facebook({ u: this.url })
                window.open(this.shareUrl, '_blank').focus();
                break;
            case 'linkedin':
                this.shareUrl = ShareUrl.linkedin({ url: this.url })
                window.open(this.shareUrl, '_blank').focus();
                break;
            case 'twitter':
                this.shareUrl = ShareUrl.twitter({ url: this.url })
                window.open(this.shareUrl, '_blank').focus();
                break;
            case 'pinterest':
                this.shareUrl = ShareUrl.pinterest({
                    url: this.url,
                    media : ev.currentTarget.dataset.shareImg
                })
                window.open(this.shareUrl, '_blank').focus();
                break;
            case 'email':
                this.shareUrl = ShareUrl.email({ subject: 'Tourisme Basses-Laurentides', body: this.url })
                window.open(this.shareUrl).focus();
                break;
            default:
                break;
        }
    }
}
