var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-accordion-item" }, [
    _c(
      "button",
      {
        staticClass: "c-accordion-button",
        attrs: {
          "aria-expanded": _vm.isOpen,
          "aria-controls": "collapse" + _vm._uid,
        },
        on: {
          click: function ($event) {
            return _vm.toggleAccordion()
          },
        },
      },
      [
        _c("div", { staticClass: "c-accordion-item__title" }, [
          _c("h1", [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "c-button-arrow",
            class: { "c-button-arrow--down": _vm.isOpen },
          },
          [
            _c("svg", { staticClass: "c-icon" }, [
              _c("use", {
                attrs: {
                  "xlink:href":
                    "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                },
              }),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "wrapper",
        staticClass: "c-accordion-item__content-wrapper",
        class: { "c-accordion-item__content-wrapper--hide": !_vm.isOpen },
        attrs: { id: "collapse" + _vm._uid },
      },
      [
        _c(
          "div",
          { ref: "content", staticClass: "c-accordion-item__content" },
          [
            _vm._t("content", [
              _vm._v(
                "\n              Default content of the Accordion Item component\n          "
              ),
            ]),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }