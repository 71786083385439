import Component from '../classes/Component'

let searchBtn
let searchInput

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        this.searchBtn = this.args.searchBtn
        this.searchInput = this.element.querySelector('.c-search__input')

        this.searchBtn.addEventListener('click', ev => {
            ev.preventDefault()
            this.toggleSearch()
        })

        this.element.querySelector('.c-search__clear').addEventListener('click', ev => {
            ev.preventDefault()
            this.resetForm()
            this.searchInput.focus()
        })

        this.onKeyUp()

        // On key up add event
        this.searchInput.addEventListener('keyup', ev => {
            this.onKeyUp()
        })
    }

    toggleSearch () {
        if(this.element.classList.contains('is-active')) {
            this.closeSearch()
        } else {
            this.openSearch()
        }
    }

    openSearch () {
        this.element.classList.add('is-active')
        document.addEventListener('click', this.closeOnClickOutside)
        // Input autofocus on open
        this.searchInput.focus()
        // Emit event
        this.emit('onSearchOpen', this)
    }

    closeSearch () {
        this.element.classList.remove('is-active')
        document.removeEventListener('click', this.closeOnClickOutside)
        // Clear form
        this.resetForm()
        // On key up remove event
        // this.searchInput.removeEventListener('keyup', ev => {
        //     this.onKeyUp()
        // })
    }

    resetForm () {
        this.element.querySelector('form').reset()
        this.element.querySelector('.c-search__input').value = ""
        this.onKeyUp()
    }

    closeOnClickOutside (ev) {
        if (!ev.target.closest('[data-search]') && !ev.target.closest('[data-search-btn]')) {
            this.closeSearch()
        }
    }

    onKeyUp () {
        if(this.searchInput.value != '') {
            this.element.querySelector('.c-search__clear').classList.add('is-active')
        } else {
            this.element.querySelector('.c-search__clear').classList.remove('is-active')
        }
    }
}
