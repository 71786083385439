var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-dropdown", attrs: { "data-type": _vm.filterarg } },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          options: _vm.options,
          reduce: function (option) {
            return option.id
          },
          searchable: false,
        },
        on: { input: _vm.onChange },
        model: {
          value: _vm.selectedOption,
          callback: function ($$v) {
            _vm.selectedOption = $$v
          },
          expression: "selectedOption",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }