var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-accordion" },
    [
      _c("div", { staticClass: "o-layout" }, [
        _c("div", { staticClass: "o-layout__item u-12/12 u-text-center" }, [
          _c("div", { staticClass: "c-accordion-title" }, [
            _vm.title
              ? _c("h2", { staticClass: "u-h2" }, [_vm._v(_vm._s(_vm.title))])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }