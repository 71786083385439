import Component from '../classes/Component'
import Swiper, { Navigation, EffectFade, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        Swiper.use([ Navigation, EffectFade, Autoplay ])

        let slidesCount = Array.from(this.element.querySelectorAll('.swiper-slide')).length;

        let swiper = new Swiper(this.element.querySelector('.swiper'), {
            speed: 1000,
            autoplay: {
                delay: 3000
            },
            loop: slidesCount > 1,
            fadeEffect: { crossFade: true },
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            watchOverflow: true,
            effect: "fade"
        });
    }
}
