import ThemeSwitcher from './utils/ThemeSwitcher' // TODO: Remove for production with dependencies

import Vue from 'vue'

import InlineSvg from 'vue-inline-svg'
import BannerCarousel from './components/BannerCarousel'
import BulletCarousel from './components/BulletCarousel'
import ImageCarousel from './components/ImageCarousel'
import RoundedCarousel from './components/RoundedCarousel'
import CustomSelect from './utils/CustomSelect'
import InteractiveMap from './components/InteractiveMap'
import DatePicker from './components/DatePicker.vue'
import Accordion from './components/Accordion.vue'
import AccordionItem from './components/AccordionItem.vue'
import TabList from './components/TabList.vue'
import SearchResult from './components/SearchResult.vue'
import Dropdown from './ui/dropdown/Dropdown.vue'
import Bookmarks from './components/Bookmarks.vue'
import AnnouncementBar from './components/AnnouncementBar'
import MobileNav from './components/MobileNav'
import StickyHeader from './components/StickyHeader'
import Search from './components/Search'
import Quiz from './components/Quiz.vue'
import EventsResults from './components/EventsResults.vue'
import StickyCard from './components/StickyCard'
import ShareLocation from './components/ShareLocation'
import PrintPage from './utils/PrintPage'
import BookmarksStorage from './components/BookmarksStorage'
import 'lazysizes'

let announcementBar
let mobileNav

class App {
    constructor () {
        this.init()
    }

    init () {
        document.documentElement.classList.remove('no-js')

        // console.log('NODE_ENV =>', process.env.NODE_ENV)
        // console.log('SERVER_ENV =>', process.env.SERVER_ENV)
        if (process.env.NODE_ENV === "development") Vue.config.devtools = true
        // Theme Switcher
        if(process.env.SERVER_ENV == 'development' || process.env.SERVER_ENV == 'staging') {
            let themeSwitcher = new ThemeSwitcher('#app')
        }

        // Vue Instance
        new Vue({
            el: '#app',
            data: {},
            delimiters: ['${', '}'],
            mounted () {
                //-------------------------------------------
                // Vanilla JS Components
                //-------------------------------------------

                // If header exists
                if(document.querySelector('[data-header]')) {
                    // Announcement Bar
                    this.announcementBar = new AnnouncementBar(document.querySelector('[data-announcement-bar'))
                    this.announcementBar.on('onUpdate', this.updateMobileNavPadding)

                    // Mobile Nav
                    this.mobileNav = new MobileNav(document.querySelector('[data-mobile-nav'), {
                        desktopNav: document.querySelector('[data-desktop-nav'),
                        header: document.querySelector('[data-header'),
                        burger: document.querySelector('[data-burger-icon')
                    })

                    // Sticky header
                    const stickyHeader = new StickyHeader(document.querySelector('.c-wrapper'), document.querySelector('[data-sticky-header]'))

                    // Search
                    Array.from(document.querySelectorAll('[data-search]')).forEach((item, index) => {
                        let search = new Search(item, {
                            searchBtn: document.querySelector('[data-search-btn]')
                        })

                        search.on('onSearchOpen', () => {
                            this.mobileNav.closeNav()
                        })
                    })
                }

                // Rounded carousels
                Array.from(document.querySelectorAll('[data-rounded-carousel]')).forEach((item, index) => {
                    let roundedCarousels = new RoundedCarousel(item)
                })

                // Image carousels
                Array.from(document.querySelectorAll('[data-image-carousel]')).forEach((item, index) => {
                    let imageCarousels = new ImageCarousel(item)
                })

                Array.from(document.querySelectorAll('[data-bullet-carousel]')).forEach((item, index) => {
                    let bulletCarousel = new BulletCarousel(item)
                })

                // HomeCarousel Component
                Array.from(document.querySelectorAll('[data-banner-carousel]')).forEach((item, index) => {
                    let bannerCarousel = new BannerCarousel(item)
                })

                // Custom color for first option in select input (placeholder's color)
                Array.from(document.querySelectorAll('select.js-placeholder')).forEach((item, index) => {
                    let customSelect = new CustomSelect(item)
                })

                // What to do? pages sticky card top position
                Array.from(document.querySelectorAll('[data-sticky-card]')).forEach((item, index) => {
                    let stickyCard = new StickyCard(item)
                })

                // Share pages on social media
                const shareLocation = new ShareLocation(document.querySelector('[data-share]'))

                // Share pages on social media
                const printPage = new PrintPage()

                // Bookmarks local storage
                const bookmarksStorage = new BookmarksStorage()

                this.$on('searchResultsUpdate', bookmarksStorage.init)
                this.$on('bookmarkRemoved', bookmarksStorage.remove)
            },
            methods: {
                initMap: function (event) {
                    // Intercative SVG Map
                    let interactiveMap = new InteractiveMap(event.parentNode.closest('[data-interactive-map]'))
                },
                updateMobileNavPadding: function () {
                    this.mobileNav.updatePadding()
                },
                filterBlogPosts (value) {
                    let currrentQueryParams

                    if(window.location.search) {
                        currrentQueryParams = JSON.parse('{"' + decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
                    }else{
                        currrentQueryParams = {}
                    }


                    let filtersRefs = ['blogFilterTheme', 'blogFilterAuthor']

                    let filters = Array.from(document.querySelectorAll('.c-dropdown'))

                    let urlParams = {}

                    filters.forEach((item, index) => {
                        if(typeof this.$refs[filtersRefs[index]].getData() != 'object') {
                            urlParams[item.dataset.type] = this.$refs[filtersRefs[index]].getData()
                        }
                    })

                    let mergeQueryParams = { ...currrentQueryParams, ...urlParams }

                    let queryString = '?' + Object.keys(mergeQueryParams).map(key => key + '=' + mergeQueryParams[key]).join('&')

                    window.location.href = window.location.href.split('?')[0] + queryString
                },
                reloadPage (keepQueryString = true) {
                    if(keepQueryString) {
                        window.location.reload()
                    }else{
                        window.location = window.location.href.split("?")[0]
                    }
                }
            },
            components: {
                'inline-svg': InlineSvg,
                'app-date-picker': DatePicker,
                'app-accordion': Accordion,
                'app-accordion-item': AccordionItem,
                'app-tab-list': TabList,
                'app-search-result': SearchResult,
                'app-dropdown': Dropdown,
                'app-bookmarks': Bookmarks,
                'app-quiz': Quiz,
                'app-events-results': EventsResults
            }
        })
    }
}

const app = new App()
