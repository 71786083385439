<template>
    <div>
        <div class="o-layout">
            <div v-for="(item, index) in content.slice(0, this.itemsPerPage)" :key="index" class="o-layout__item u-12/12 u-6/12@tablet u-4/12@desktop u-margin-bottom">
                <a v-if="item.type === 'adventure-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                    <div class="c-card__inner" >
                        <div class="c-card-primary__body">
                            <div class="c-card-primary__pic">
                                <img :src="item.image" :alt="item.name">
                            </div>
                            <div class="c-card-primary__footer">
                                <div class="c-card-primary__footer-text">
                                    <p v-if="item.name">{{ item.name }}</p>
                                    <p v-else>Untitled</p>
                                    <p v-if="item.location" class="c-card-primary__footer-icon">
                                        <svg class="c-icon c-icon--small u-color-primary">
                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                        </svg>
                                        <small>{{ item.location }}</small>
                                    </p>
                                </div>
                                <div class="c-button-arrow">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                </a>
                <a v-if="item.type === 'activities-event-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                    <div class="c-card__inner">
                        <div class="c-card-primary__body">
                            <div class="c-card-primary__pic">
                                <img :src="item.image" :alt="item.name">
                            </div>
                            <div class="c-card-primary__footer">
                                <div class="c-card-primary__footer-text">
                                    <p v-if="item.name">{{ item.name }}</p>
                                    <p v-else>Untitled</p>
                                    <p v-if="item.location" class="c-card-primary__footer-icon">
                                        <svg class="c-icon c-icon--small u-color-primary">
                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                        </svg>
                                        <small>{{ item.location }}</small>
                                    </p>
                                    <p v-if="item.date.from || item.date.to" class="c-card-primary__footer-icon">
                                        <svg class="c-icon c-icon--small u-color-primary">
                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-calendrier"></use>
                                        </svg>
                                        <small v-if="item.date.from && !item.date.to">
                                            {{ item.date.from.full }} {{ item.date.from.light.year}}
                                        </small>
                                        <small v-if="item.date.from && item.date.to">
                                            Du {{ item.date.from.full }} au {{ item.date.to.full }} {{ item.date.to.light.year}}
                                        </small>
                                    </p>
                                </div>
                                <div class="c-button-arrow">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="item.date.from || item.date.to" class="c-card__flag">
                        <div v-if="item.date.from">
                            <span class="c-card__flag-title">{{ item.date.from.light.day }}</span>
                            <span class="c-card__flag-subtitle">{{ item.date.from.light.month }}</span>
                        </div>
                        <hr v-if="item.date.to">
                        <div v-if="item.date.to">
                            <span class="c-card__flag-title">{{ item.date.to.light.day }}</span>
                            <span class="c-card__flag-subtitle">{{ item.date.to.light.month }}</span>
                        </div>
                    </div>
                    <div class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                </a>
                <a v-if="item.type === 'blog-card' || item.type === 'cities-card' || item.type === 'services-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                    <div class="c-card__inner">
                        <div class="c-card-primary__body">
                            <div class="c-card-primary__pic">
                                <img :src="item.image" :alt="item.name">
                            </div>
                            <div class="c-card-primary__footer">
                                <div class="c-card-primary__footer-text">
                                    <span v-if="item.name">{{ item.name }}</span>
                                    <span v-else>Untitled</span>
                                </div>
                                <div class="c-button-arrow">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                </a>
                <a v-if="item.type === 'attractions-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                    <div class="c-card__inner">
                        <div class="c-card-primary__body">
                            <div class="c-card-primary__pic">
                                <img :src="item.image" :alt="item.name">
                            </div>
                            <div class="c-card-primary__footer">
                                <div class="c-card-primary__footer-text">
                                    <p v-if="item.name">{{ item.name }}</p>
                                    <p v-else>Untitled</p>
                                    <div class="c-card-primary__footer-text__icons">
                                        <p v-if="item.days" class="c-card-primary__footer-icon">
                                            <svg class="c-icon c-icon--small u-color-primary">
                                                <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-calendrier"></use>
                                            </svg>
                                            <small>{{ item.days }} jours</small>
                                        </p>
                                        <p v-if="item.cities" class="c-card-primary__footer-icon">
                                            <svg class="c-icon c-icon--small u-color-primary">
                                                <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                            </svg>
                                            <small>{{ item.cities }}</small>
                                        </p>
                                    </div>
                                </div>
                                <div class="c-button-arrow">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                </a>
            </div>
        </div>
        <div class="o-layout u-padding-top">
            <div class="o-layout__item u-12/12 u-text-center">
                <div class="c-pagination">
                    <a href="#" class="c-pagination__prev" :class="{'is-disabled': activePage == 1}">
                        <svg class="c-icon">
                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-gauche"></use>
                        </svg>
                    </a>
                    <ul class="c-pagination__numbers o-list-inline u-margin-none">
                        <li v-for="index in pageCount" :key="index" class="o-list-inline__item">
                            <a href="#" :class="{'is-active': index == activePage}"><span>{{ index }}</span></a>
                        </li>
                    </ul>
                    <a href="#" class="c-pagination__next" :class="{'is-disabled': activePage == pageCount}">
                        <svg class="c-icon">
                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: '',
        content: {
            type: Array
        },
        pageCount: 0,
        activePage: null,
        itemsPerPage: 0
    },
    computed: {
        contentMax() {
            let slicedContent = this.content.slice(0, this.itemsPerPage)
            return slicedContent
        }
    }
}
</script>
