var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-datepicker",
      class: { "c-datepicker--translate-up": _vm.isTranslated },
    },
    [
      _c("VueDatePicker", {
        attrs: {
          inline: "",
          range: "",
          locale: _vm.locale,
          color: _vm.color,
          "min-date": _vm.minDate,
          "max-date": _vm.maxDate,
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }