import Component from '../classes/Component'

let header

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        this.header = document.querySelector('[data-header]')

        window.addEventListener('resize', ev => {
            this.updatePosition(ev)
        })

        this.updatePosition()
    }

    updatePosition (ev) {
        this.element.style.top = `${this.header.offsetHeight + 10}px`
    }
}
