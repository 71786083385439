<template>
    <div class="c-tab-list">

        <ul class="c-tab-item">
            <li
                v-for="(tab, index) in tabs" :key="index"
                v-bind:class="{ 'c-tab-item--active' : tab.isActive }"
                @click="makeActive(index)"
                :data-index="index"
                :data-tab-name="tab.content[0].contentType"
            >
                <h3>{{ tab.name }} ({{ tab.totalItems || tab.content.length }})</h3>
            </li>
        </ul>

        <div class="c-tab-list__dropdown">
            <app-dropdown ref="dropdown" v-on:change="dropdownChange"></app-dropdown>
        </div>

        <div class="o-layout">
            <div class="o-layout__item">
                <div
                    class="c-tab-list__content"
                    v-for="(tab, index) in tabs"
                    :key="index"
                    v-show="tab.isActive"
                >
                    <div class="o-layout">
                        <div v-for="(item, index) in tab.content.slice(0, tab.itemsPerPage)" :key="index" class="o-layout__item u-12/12 u-6/12@tablet u-4/12@desktop u-margin-bottom">
                            <a v-if="item.type === 'adventure-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner" >
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <p v-if="item.name">{{ item.name }}</p>
                                                <p v-else>Untitled</p>
                                                <p v-if="item.location" class="c-card-primary__footer-icon">
                                                    <svg class="c-icon c-icon--small u-color-primary">
                                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                                    </svg>
                                                    <small>{{ item.location }}</small>
                                                </p>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)" class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                            <a v-if="item.type === 'activities-event-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner">
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <p v-if="item.name">{{ item.name }}</p>
                                                <p v-else>Untitled</p>
                                                <p v-if="item.location" class="c-card-primary__footer-icon">
                                                    <svg class="c-icon c-icon--small u-color-primary">
                                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                                    </svg>
                                                    <small>{{ item.location }}</small>
                                                </p>
                                                <p v-if="item.date.from || item.date.to" class="c-card-primary__footer-icon">
                                                    <svg class="c-icon c-icon--small u-color-primary">
                                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-calendrier"></use>
                                                    </svg>
                                                    <small v-if="item.date.from.date.getTime() == item.date.to.date.getTime()">
                                                        {{ item.date.from.full | capitalizeFirstLetter  }}<span v-if="typeof item.hour == 'string' && item.hour != ''">, {{ item.hour }}</span>
                                                    </small>
                                                    <small v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                                                        Du {{ item.date.from.full }} au {{ item.date.to.full }}<span v-if="typeof item.hour == 'string' && item.hour != ''">, {{ item.hour }}</span>
                                                    </small>
                                                </p>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="item.date.from || item.date.to" class="c-card__flag">
                                    <div v-if="item.date.from">
                                        <span class="c-card__flag-title">{{ item.date.from.light.day }}</span>
                                        <span class="c-card__flag-subtitle">{{ item.date.from.light.month }}</span>
                                    </div>
                                    <hr v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                                    <div v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                                        <span class="c-card__flag-title">{{ item.date.to.light.day }}</span>
                                        <span class="c-card__flag-subtitle">{{ item.date.to.light.month }}</span>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)"  class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                            <a v-if="item.type === 'blog-card' || item.type === 'cities-card' || item.type === 'services-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner">
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <span v-if="item.name">{{ item.name }}</span>
                                                <span v-else>Untitled</span>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)" class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                            <a v-if="item.type === 'attractions-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner">
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <p v-if="item.name">{{ item.name }}</p>
                                                <p v-else>Untitled</p>
                                                <div class="c-card-primary__footer-text__icons">
                                                    <p v-if="item.days" class="c-card-primary__footer-icon">
                                                        <svg class="c-icon c-icon--small u-color-primary">
                                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-calendrier"></use>
                                                        </svg>
                                                        <small>{{ item.days }} jours</small>
                                                    </p>
                                                    <p v-if="item.cities" class="c-card-primary__footer-icon">
                                                        <svg class="c-icon c-icon--small u-color-primary">
                                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                                        </svg>
                                                        <small>{{ item.cities }} </small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)" class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                        </div>
                    </div>
                    <div v-show="tab.pageCount > 1" class="o-layout u-padding-top pagination-tab">
                        <div class="o-layout__item u-12/12 u-text-center">
                            <div class="c-pagination">
                                <a href="#" @click="toPrevTab" :data-index="1" class="c-pagination__prev" :class="{'is-disabled': tab.activePage == 1}">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-gauche"></use>
                                    </svg>
                                </a>
                                <ul class="c-pagination__numbers o-list-inline u-margin-none">
                                    <li v-for="index in tab.pageCount" :key="index" class="o-list-inline__item">
                                        <a href="#" :class="{'is-active': index == tab.activePage}" @click="changePagination" ><span :data-index="index">{{ index }}</span></a>
                                    </li>
                                </ul>
                                <a href="#" @click="toNextTab" :data-index="tab.pageCount" class="c-pagination__next" :class="{'is-disabled': tab.activePage == tab.pageCount}">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TabContent from '../components/TabContent.vue';
import Dropdown from '../ui/dropdown/Dropdown.vue';

export default {
    data() {
        return {
            tabs: [],
            initial: false,
            initialTab: 0,
            indexPages: []
        };
    },
    filters : {
        capitalizeFirstLetter : function(value){
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
    components: {
        'app-tab-content': TabContent,
        'app-dropdown': Dropdown
    },
    props: {
        type: '',
        all: Boolean
    },
    updated() {
        // Hook to handle bookmarks when template is updated
        this.$root.$emit('searchResultsUpdate')
    },
    methods: {
        // Return tab total items
        count(tab) {
            return {...tab}.content.length;
        },
        getAllItems()
        {
            let totalItems = [];
            this.tabs.forEach(el => {
                const currentEl = {...el};
                currentEl.content.forEach(data => {
                    totalItems.push(data);
                });
            });

            return totalItems;
        },
        allInactive()
        {
            this.tabs.forEach(element => {
                element.isActive = false
            });
        },
        // Executes when a tab is selected
        makeActive(tabIndex)
        {
            this.allInactive();
            this.tabs[tabIndex].isActive = true;
            if(tabIndex == 0) {
                window.activeTab = null
            }else{
                window.activeTab = this.tabs[tabIndex].content[0].contentType;
            }
            // Trigger Event to handle Bookmarks
            this.$root.$emit('searchResultsUpdate')
        },
        hasTabActive() {
            this.tabs.forEach((el, index) => {
                if(el.isActive == true) {
                    this.initialTab = index + 1
                    return true
                }
            });

            return false
        },
        // Updates results displayed when a new search result is
        updateTabs(datas) {
            this.tabs = datas

            let hasActive = false
            this.tabs.forEach((item, index) => {
                if(item.isActive) {
                    hasActive = true
                    this.makeActive(index)
                    window.activeTab = item.content[0].contentType
                }
            })

            if(hasActive == false) {
                this.makeActive(0)
            }

            this.$refs.dropdown.update(this.tabs)
        },
        // When dropdown changes (mobile only)
        dropdownChange(value) {
            this.makeActive(value)
            if(value != 0) {
                window.activeTab = this.tabs[value].content[0].contentType
            }else{
                window.activeTab = null
            }
        },
        // Remove bookmark and relaod search to update template properly
        removeBookmark(ev) {
            if(ev.target.classList.contains('c-button-bookmark--close')) {
                this.$root.$emit('bookmarkRemoved', ev.target)
                this.$emit('bookmark-removed')
            }
        },
        changePagination(ev) {

            let index = ev.target.dataset.index;
            let indexTab = document.querySelector('.c-tab-item--active').dataset.index;
            this.makeActive(indexTab);
            this.$parent.research(index, 12);
        },
        toPrevTab(ev) {
            let index = ev.target.dataset.index
            let indexTab = document.querySelector('.c-tab-item--active').dataset.index;
            this.makeActive(indexTab);
            this.$parent.research(index, 12);
        },
        toNextTab(ev) {
            let index = ev.target.dataset.index
            let indexTab = document.querySelector('.c-tab-item--active').dataset.index;
            this.makeActive(indexTab);
            this.$parent.research(index, 12);

        },
    }
}
</script>
