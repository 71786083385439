import Component from '../classes/Component'
import Swiper, { Navigation, Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import "swiper/css/pagination";

let slides

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        Swiper.use([Navigation, Autoplay, Pagination])

        this.slides = Array.from(this.element.querySelectorAll('.swiper-slide'))
        let slidesCount = this.slides.length;

        // this.slides.forEach((item) => {
        //     let iframe = item.querySelector('iframe')
        //     if(iframe) {
        //         iframe.addEventListener('click', ev => {
        //             console.log('swiper clicked')
        //         })
        //     }
        // })

        let swiper = new Swiper(this.element.querySelector('.swiper'), {
            speed: 1000,
            autoplay: {
                delay: 3000
            },
            loop: slidesCount > 1,
            watchOverflow: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            on: {
                slideChange: function(el) {
                    this.slides.forEach((item) => {
                        let iframe = item.querySelector('iframe')
                        if(iframe) {
                            if(iframe.classList.contains('youtube')) {
                                iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
                            }
                        }
                    })
                }
            }
        });
    }
}
