var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-search-result" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.localStorage.length == 0,
              expression: "localStorage.length == 0",
            },
          ],
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("app-tab-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.localStorage.length != 0,
            expression: "localStorage.length != 0",
          },
        ],
        ref: "tablist",
        attrs: { type: "bookmarks" },
        on: { "bookmark-removed": _vm.onBookmarkRemoved },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "o-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "o-layout__item u-12/12 u-6/12@desktop u-push-3/12@desktop",
        },
        [
          _c("h3", {
            staticClass: "u-h3 u-text-center",
            attrs: { id: "nofavoritetitle" },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "u-text-center",
            attrs: { id: "nofavoritesdescription" },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }