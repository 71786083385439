var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "o-container" }, [
    _c("div", { staticClass: "o-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "o-layout__item u-12/12 u-8/12@desktop o-layout__item--order-2@desktop",
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "o-layout__item u-12/12 u-4/12@desktop o-layout__item--order-1@desktop",
        },
        [
          _c("app-date-picker", {
            attrs: { "is-translated": true, min: "" },
            on: { "date-picker-updated": _vm.setDateRange },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "o-layout u-padding-top-large u-padding-top-huge@desktop",
      },
      [
        _c("div", { staticClass: "o-layout__item u-12/12" }, [
          _c(
            "h2",
            {
              staticClass: "u-h2 u-margin-bottom-tiny u-margin-bottom@desktop",
            },
            [_vm._v(_vm._s(_vm.selectedDates))]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "o-layout u-padding-top" },
      [
        _vm._l(
          _vm.searchResults.slice(0, _vm.itemsPerPage),
          function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "o-layout__item u-margin-bottom u-12/12 u-6/12@tablet u-4/12@huge",
              },
              [
                item.type === "activities-event-card"
                  ? _c(
                      "a",
                      {
                        staticClass: "c-card-primary c-card-primary--light",
                        attrs: { href: item.url },
                      },
                      [
                        _c("div", { staticClass: "c-card__inner" }, [
                          _c("div", { staticClass: "c-card-primary__body" }, [
                            _c("div", { staticClass: "c-card-primary__pic" }, [
                              _c("img", {
                                attrs: { src: item.image, alt: item.name },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-card-primary__footer" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "c-card-primary__footer-text",
                                  },
                                  [
                                    item.name
                                      ? _c("p", [_vm._v(_vm._s(item.name))])
                                      : _c("p", [_vm._v("Untitled")]),
                                    _vm._v(" "),
                                    item.location
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "c-card-primary__footer-icon",
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "c-icon c-icon--small u-color-primary",
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "/assets/assets/img/spritemap.svg#sprite-ic-map",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(_vm._s(item.location)),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.date.from || item.date.to
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "c-card-primary__footer-icon",
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "c-icon c-icon--small u-color-primary",
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "/assets/assets/img/spritemap.svg#sprite-ic-calendrier",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            item.date.from.date.getTime() ==
                                            item.date.to.date.getTime()
                                              ? _c("small", [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "capitalizeFirstLetter"
                                                        )(item.date.from.full)
                                                      )
                                                  ),
                                                  typeof item.hour ==
                                                    "string" && item.hour != ""
                                                    ? _c("span", [
                                                        _vm._v(
                                                          ", " +
                                                            _vm._s(item.hour)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.date.from.date.getTime() !=
                                            item.date.to.date.getTime()
                                              ? _c("small", [
                                                  _vm._v(
                                                    "\n                                        Du " +
                                                      _vm._s(
                                                        item.date.from.full
                                                      ) +
                                                      " au " +
                                                      _vm._s(item.date.to.full)
                                                  ),
                                                  typeof item.hour ==
                                                    "string" && item.hour != ""
                                                    ? _c("span", [
                                                        _vm._v(
                                                          ", " +
                                                            _vm._s(item.hour)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "c-button-arrow" }, [
                                  _c("svg", { staticClass: "c-icon" }, [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                      },
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.date.from || item.date.to,
                                expression: "item.date.from || item.date.to",
                              },
                            ],
                            staticClass: "c-card__flag",
                          },
                          [
                            item.date.from
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "c-card__flag-title" },
                                    [_vm._v(_vm._s(item.date.from.light.day))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "c-card__flag-subtitle" },
                                    [_vm._v(_vm._s(item.date.from.light.month))]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.date.from.date.getTime() !=
                            item.date.to.date.getTime()
                              ? _c("hr")
                              : _vm._e(),
                            _vm._v(" "),
                            item.date.from.date.getTime() !=
                            item.date.to.date.getTime()
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "c-card__flag-title" },
                                    [_vm._v(_vm._s(item.date.to.light.day))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "c-card__flag-subtitle" },
                                    [_vm._v(_vm._s(item.date.to.light.month))]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "c-button-bookmark",
                          class: {
                            "c-button-bookmark--close": _vm.type == "bookmarks",
                          },
                          attrs: {
                            "data-bookmark-content-type": item.contentType,
                            "data-bookmark-content-id": item.contentCode,
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }
        ),
        _vm._v(" "),
        _vm.searchResults.length == 0
          ? _c("div", { staticClass: "o-layout__item u-12/12 u-text-center" }, [
              _c("p", [
                _vm._v(
                  "Il n’y a aucun activité ou événement pour la date ou les dates sélectionnées. Sélectionnez d'autres dates."
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.pageCount > 1
      ? _c("div", { staticClass: "o-layout u-padding-top" }, [
          _c("div", { staticClass: "o-layout__item u-12/12 u-text-center" }, [
            _c("div", { staticClass: "c-pagination" }, [
              _c(
                "a",
                {
                  staticClass: "c-pagination__prev",
                  class: { "is-disabled": _vm.activePage == 1 },
                  attrs: { href: "#", "data-index": 1 },
                  on: { click: _vm.toPrevTab },
                },
                [
                  _c("svg", { staticClass: "c-icon" }, [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/assets/assets/img/spritemap.svg#sprite-ic-fleche-gauche",
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass:
                    "c-pagination__numbers o-list-inline u-margin-none",
                },
                _vm._l(_vm.pageCount, function (index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "o-list-inline__item" },
                    [
                      _c(
                        "a",
                        {
                          class: { "is-active": index == _vm.activePage },
                          attrs: { href: "#" },
                          on: { click: _vm.changePagination },
                        },
                        [
                          _c("span", { attrs: { "data-index": index } }, [
                            _vm._v(_vm._s(index)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "c-pagination__next",
                  class: { "is-disabled": _vm.activePage == _vm.pageCount },
                  attrs: { href: "#", "data-index": _vm.pageCount },
                  on: { click: _vm.toNextTab },
                },
                [
                  _c("svg", { staticClass: "c-icon" }, [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                      },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }