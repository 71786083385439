var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "48",
        height: "30.941",
        viewBox: "0 0 48 30.941",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "%231A5891",
          d:
            "M47.999 3.639a3.414 3.414 0 0 0-6.048-2.247l-17.9 20.89L6.165 1.392A3.414 3.414 0 1 0 .998 5.824l20.467 23.919a3.414 3.414 0 0 0 5.194 0l20.48-23.919A3.413 3.413 0 0 0 48 3.637Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }