var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "o-layout" },
      _vm._l(_vm.content.slice(0, this.itemsPerPage), function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass:
              "o-layout__item u-12/12 u-6/12@tablet u-4/12@desktop u-margin-bottom",
          },
          [
            item.type === "adventure-card"
              ? _c(
                  "a",
                  {
                    staticClass: "c-card-primary c-card-primary--light",
                    attrs: { href: item.url },
                  },
                  [
                    _c("div", { staticClass: "c-card__inner" }, [
                      _c("div", { staticClass: "c-card-primary__body" }, [
                        _c("div", { staticClass: "c-card-primary__pic" }, [
                          _c("img", {
                            attrs: { src: item.image, alt: item.name },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "c-card-primary__footer" }, [
                          _c(
                            "div",
                            { staticClass: "c-card-primary__footer-text" },
                            [
                              item.name
                                ? _c("p", [_vm._v(_vm._s(item.name))])
                                : _c("p", [_vm._v("Untitled")]),
                              _vm._v(" "),
                              item.location
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-card-primary__footer-icon",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "c-icon c-icon--small u-color-primary",
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/assets/assets/img/spritemap.svg#sprite-ic-map",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(_vm._s(item.location)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "c-button-arrow" }, [
                            _c("svg", { staticClass: "c-icon" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "c-button-bookmark",
                      class: {
                        "c-button-bookmark--close": _vm.type == "bookmarks",
                      },
                      attrs: {
                        "data-bookmark-content-type": item.contentType,
                        "data-bookmark-content-id": item.contentCode,
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "activities-event-card"
              ? _c(
                  "a",
                  {
                    staticClass: "c-card-primary c-card-primary--light",
                    attrs: { href: item.url },
                  },
                  [
                    _c("div", { staticClass: "c-card__inner" }, [
                      _c("div", { staticClass: "c-card-primary__body" }, [
                        _c("div", { staticClass: "c-card-primary__pic" }, [
                          _c("img", {
                            attrs: { src: item.image, alt: item.name },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "c-card-primary__footer" }, [
                          _c(
                            "div",
                            { staticClass: "c-card-primary__footer-text" },
                            [
                              item.name
                                ? _c("p", [_vm._v(_vm._s(item.name))])
                                : _c("p", [_vm._v("Untitled")]),
                              _vm._v(" "),
                              item.location
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-card-primary__footer-icon",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "c-icon c-icon--small u-color-primary",
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/assets/assets/img/spritemap.svg#sprite-ic-map",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(_vm._s(item.location)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.date.from || item.date.to
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-card-primary__footer-icon",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "c-icon c-icon--small u-color-primary",
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/assets/assets/img/spritemap.svg#sprite-ic-calendrier",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      item.date.from && !item.date.to
                                        ? _c("small", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.date.from.full) +
                                                " " +
                                                _vm._s(
                                                  item.date.from.light.year
                                                ) +
                                                "\n                                    "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.date.from && item.date.to
                                        ? _c("small", [
                                            _vm._v(
                                              "\n                                        Du " +
                                                _vm._s(item.date.from.full) +
                                                " au " +
                                                _vm._s(item.date.to.full) +
                                                " " +
                                                _vm._s(
                                                  item.date.to.light.year
                                                ) +
                                                "\n                                    "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "c-button-arrow" }, [
                            _c("svg", { staticClass: "c-icon" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.date.from || item.date.to,
                            expression: "item.date.from || item.date.to",
                          },
                        ],
                        staticClass: "c-card__flag",
                      },
                      [
                        item.date.from
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "c-card__flag-title" },
                                [_vm._v(_vm._s(item.date.from.light.day))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "c-card__flag-subtitle" },
                                [_vm._v(_vm._s(item.date.from.light.month))]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.date.to ? _c("hr") : _vm._e(),
                        _vm._v(" "),
                        item.date.to
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "c-card__flag-title" },
                                [_vm._v(_vm._s(item.date.to.light.day))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "c-card__flag-subtitle" },
                                [_vm._v(_vm._s(item.date.to.light.month))]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "c-button-bookmark",
                      class: {
                        "c-button-bookmark--close": _vm.type == "bookmarks",
                      },
                      attrs: {
                        "data-bookmark-content-type": item.contentType,
                        "data-bookmark-content-id": item.contentCode,
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "blog-card" ||
            item.type === "cities-card" ||
            item.type === "services-card"
              ? _c(
                  "a",
                  {
                    staticClass: "c-card-primary c-card-primary--light",
                    attrs: { href: item.url },
                  },
                  [
                    _c("div", { staticClass: "c-card__inner" }, [
                      _c("div", { staticClass: "c-card-primary__body" }, [
                        _c("div", { staticClass: "c-card-primary__pic" }, [
                          _c("img", {
                            attrs: { src: item.image, alt: item.name },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "c-card-primary__footer" }, [
                          _c(
                            "div",
                            { staticClass: "c-card-primary__footer-text" },
                            [
                              item.name
                                ? _c("span", [_vm._v(_vm._s(item.name))])
                                : _c("span", [_vm._v("Untitled")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "c-button-arrow" }, [
                            _c("svg", { staticClass: "c-icon" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "c-button-bookmark",
                      class: {
                        "c-button-bookmark--close": _vm.type == "bookmarks",
                      },
                      attrs: {
                        "data-bookmark-content-type": item.contentType,
                        "data-bookmark-content-id": item.contentCode,
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "attractions-card"
              ? _c(
                  "a",
                  {
                    staticClass: "c-card-primary c-card-primary--light",
                    attrs: { href: item.url },
                  },
                  [
                    _c("div", { staticClass: "c-card__inner" }, [
                      _c("div", { staticClass: "c-card-primary__body" }, [
                        _c("div", { staticClass: "c-card-primary__pic" }, [
                          _c("img", {
                            attrs: { src: item.image, alt: item.name },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "c-card-primary__footer" }, [
                          _c(
                            "div",
                            { staticClass: "c-card-primary__footer-text" },
                            [
                              item.name
                                ? _c("p", [_vm._v(_vm._s(item.name))])
                                : _c("p", [_vm._v("Untitled")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "c-card-primary__footer-text__icons",
                                },
                                [
                                  item.days
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "c-card-primary__footer-icon",
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "c-icon c-icon--small u-color-primary",
                                            },
                                            [
                                              _c("use", {
                                                attrs: {
                                                  "xlink:href":
                                                    "/assets/assets/img/spritemap.svg#sprite-ic-calendrier",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(item.days) + " jours"
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.cities
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "c-card-primary__footer-icon",
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "c-icon c-icon--small u-color-primary",
                                            },
                                            [
                                              _c("use", {
                                                attrs: {
                                                  "xlink:href":
                                                    "/assets/assets/img/spritemap.svg#sprite-ic-map",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(_vm._s(item.cities)),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "c-button-arrow" }, [
                            _c("svg", { staticClass: "c-icon" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "c-button-bookmark",
                      class: {
                        "c-button-bookmark--close": _vm.type == "bookmarks",
                      },
                      attrs: {
                        "data-bookmark-content-type": item.contentType,
                        "data-bookmark-content-id": item.contentCode,
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "o-layout u-padding-top" }, [
      _c("div", { staticClass: "o-layout__item u-12/12 u-text-center" }, [
        _c("div", { staticClass: "c-pagination" }, [
          _c(
            "a",
            {
              staticClass: "c-pagination__prev",
              class: { "is-disabled": _vm.activePage == 1 },
              attrs: { href: "#" },
            },
            [
              _c("svg", { staticClass: "c-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-gauche",
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "c-pagination__numbers o-list-inline u-margin-none",
            },
            _vm._l(_vm.pageCount, function (index) {
              return _c(
                "li",
                { key: index, staticClass: "o-list-inline__item" },
                [
                  _c(
                    "a",
                    {
                      class: { "is-active": index == _vm.activePage },
                      attrs: { href: "#" },
                    },
                    [_c("span", [_vm._v(_vm._s(index))])]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "c-pagination__next",
              class: { "is-disabled": _vm.activePage == _vm.pageCount },
              attrs: { href: "#" },
            },
            [
              _c("svg", { staticClass: "c-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                  },
                }),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }