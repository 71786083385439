import Component from '../classes/Component'

let active

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        this.updateState()

        this.element.querySelector('.c-announcement-bar__close').addEventListener('click', ev => {
            ev.preventDefault()
            this.close()
        })
    }

    updateState () {
        if(sessionStorage.getItem('announcement')) {
            this.active = sessionStorage.getItem('announcement')
        } else {
            this.active = true
        }

        if(this.active == true) {
            this.element.classList.remove('is-hidden')
        } else {
            this.element.classList.add('is-hidden')
        }

        this.emit('onUpdate', this)
    }

    close () {
        sessionStorage.setItem('announcement', false)
        this.updateState()
    }
}
