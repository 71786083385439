import Component from '../classes/Component'
import Swiper, { Navigation, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        Swiper.use([Navigation, Autoplay])

        let slidesCount = Array.from(this.element.querySelectorAll('.swiper-slide')).length;

        let swiper = new Swiper(this.element.querySelector('.swiper'), {
            speed: 1000,
            autoplay: {
                delay: 3000
            },
            loop: slidesCount > 1,
            watchOverflow: true,
            navigation: {
                nextEl: this.element.querySelector('.swiper-button-next'),
                prevEl: this.element.querySelector('.swiper-button-prev')
            }
        });
    }
}
