import Component from '../classes/Component'
import { lock, unlock } from 'tua-body-scroll-lock'
import SVGInjector from 'svg-injector'

let mobileNav
let desktopNav
let header
let burger

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        // Register elements
        this.mobileNav = this.element
        this.desktopNav = this.args.desktopNav
        this.header = this.args.header
        this.burger = this.args.burger

        // Register inlined nav icons
        let svgsToInject = this.desktopNav.querySelectorAll('[data-svg-inject]')

        SVGInjector(svgsToInject, {}, function () {
            // Add elements to DOM
            this.build()
        }.bind(this))
    }

    build () {
        // Clone desktop menu
        this.mobileNav.querySelector('[data-mobile-nav-inner]').appendChild(this.desktopNav.querySelector('.c-nav__main').cloneNode(true))
        this.mobileNav.querySelector('[data-mobile-nav-inner]').appendChild(this.desktopNav.querySelector('.c-nav__menu-secondary').cloneNode(true))

        // Adjust padding (current header's height)
        this.updatePadding()
        window.addEventListener('resize', this.updatePadding)

        Array.from(this.mobileNav.querySelectorAll('.has-submenu')).forEach((item) => {
            item.addEventListener('click', ev => {
                if(!item.classList.contains('has-redirect')) {
                    let links = Array.from(item.querySelectorAll('a'))
                    links.forEach((link) => {
                        link.addEventListener('click', ev => {
                            ev.stopPropagation()
                        })
                    })
                    ev.preventDefault()
                    this.handleAccordion(ev.currentTarget)
                }
            })
        })

        // Burger icon event
        this.burger.addEventListener('click', ev => {
            this.toggleNav(ev)
        })
    }

    updatePadding () {
        this.mobileNav.style.paddingTop = `${this.header.offsetHeight}px`;
    }

    toggleNav (ev) {
        if(!this.mobileNav.classList.contains('is-active')) {
            this.openNav()
        } else {
            this.closeNav()
        }
    }

    openNav () {
        // Open nav
        this.burger.classList.add('is-active')
        this.mobileNav.classList.add('is-active')
        // Lock body scroll
        lock(this.element)
        // Hide social follow buttons
        document.querySelector('.c-header__follow-sc').classList.add('is-hidden')
    }

    closeNav () {
        // Close nav
        this.burger.classList.remove('is-active')
        this.mobileNav.classList.remove('is-active')
        // Unlock body scroll
        unlock(this.element)
        // Show social follow buttons
        document.querySelector('.c-header__follow-sc').classList.remove('is-hidden')
        // Reset accordion
        this.reset()
    }

    handleAccordion (el) {
        Array.from(this.mobileNav.querySelectorAll('.has-submenu')).forEach((item) => {
            if(el == item) {
                item.classList.toggle('is-expanded')
            } else {
                item.classList.remove('is-expanded')
            }
        })
    }

    reset () {
        // Close all accordion items
        Array.from(this.mobileNav.querySelectorAll('.has-submenu')).forEach((item) => {
            item.classList.remove('is-expanded')
        })
    }
}
