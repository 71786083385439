var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "c-quiz u-padding-vertical-large",
      class: { "u-bgcolor-teal-blue": _vm.currentStepNumber > 0 },
    },
    [
      _c("div", { staticClass: "o-container" }, [
        _vm.currentStepNumber > 0
          ? _c("div", { staticClass: "o-layout u-margin-bottom-large" }, [
              _c(
                "div",
                { staticClass: "o-layout__item u-text-center u-12/12" },
                [
                  _c(
                    "div",
                    { staticClass: "c-quiz__stepper" },
                    _vm._l(_vm.steps, function (step, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "c-quiz__stepper-item",
                          class: {
                            "is-active": _vm.currentStepNumber == step.number,
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(step.title))]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#", "data-step": step.number },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.goToStepHandler($event)
                                },
                              },
                            },
                            [_vm._v(_vm._s(step.number))]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "o-layout" }, [
          _vm.currentStepNumber == 0
            ? _c(
                "div",
                { staticClass: "o-layout__item u-12/12" },
                [
                  _vm._t("default", null, {
                    currentStepNumber: 0,
                    start: _vm.start,
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStepNumber > 0
            ? _c("div", { staticClass: "o-layout__item u-12/12" }, [
                !_vm.currentStep.isFinal && _vm.currentStepQuestion
                  ? _c(
                      "div",
                      {
                        staticClass: "c-quiz__step",
                        attrs: { "data-step": _vm.currentStep.number },
                      },
                      [
                        _c("h2", { staticClass: "u-h2 u-color-white" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("get_value")(_vm.currentStepQuestion.title)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.currentStepQuestion.isSingle
                          ? _c(
                              "div",
                              { staticClass: "c-quiz__field-group" },
                              [
                                _vm._l(
                                  _vm.currentStepAnswers,
                                  function (answer, answerIndex) {
                                    return [
                                      answer.code != "Default"
                                        ? _c(
                                            "div",
                                            {
                                              key: answerIndex,
                                              staticClass: "c-quiz__item",
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "checkbox",
                                                  id: answer.code,
                                                  name: _vm.currentStep.code,
                                                  "data-code": answer.code,
                                                  "data-season":
                                                    _vm.currentStepQuestion
                                                      .useSeason,
                                                },
                                                domProps: { value: answer.id },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.selectedCriteria(
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "c-quiz__item-label",
                                                  attrs: { for: answer.code },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(answer.title)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.currentStepQuestion.isSingle
                          ? _c(
                              "div",
                              { staticClass: "c-quiz__field-group" },
                              [
                                _vm._l(
                                  _vm.currentStepAnswers,
                                  function (answer, answerIndex) {
                                    return [
                                      answer.code != "Default"
                                        ? _c(
                                            "div",
                                            {
                                              key: answerIndex,
                                              staticClass: "c-quiz__item",
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "radio",
                                                  id: answer.code,
                                                  name: _vm.currentStep.code,
                                                  "data-code": answer.code,
                                                  "data-season":
                                                    _vm.currentStepQuestion
                                                      .useSeason,
                                                },
                                                domProps: { value: answer.id },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.selectedCriteria(
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "c-quiz__item-label",
                                                  attrs: { for: answer.code },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(answer.title)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "c-button u-margin-top-large",
                            attrs: {
                              href: "#",
                              "data-step": _vm.currentStep.number,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.next($event)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("Suivant")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "c-button__icon" }, [
                              _c("svg", { staticClass: "c-icon" }, [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentStep.isFinal
                  ? _c(
                      "div",
                      {
                        staticClass: "c-quiz__step",
                        attrs: { "data-step": _vm.currentStep.number },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "u-h2 u-color-white u-margin-bottom-large",
                          },
                          [_vm._v("Vos suggestions d'escapades")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "o-layout" },
                          [
                            _vm._l(
                              _vm.searchResults,
                              function (item, itemIdex) {
                                return _c(
                                  "div",
                                  {
                                    key: itemIdex,
                                    staticClass:
                                      "o-layout__item u-margin-bottom u-12/12 u-6/12@tablet u-4/12@huge",
                                  },
                                  [
                                    item.type === "adventure-card"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "c-card-primary c-card-primary--light",
                                            attrs: { href: item.url },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "c-card__inner" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-card-primary__body",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__pic",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: item.image,
                                                            alt: item.name,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__footer",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-card-primary__footer-text",
                                                          },
                                                          [
                                                            item.name
                                                              ? _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.name
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("p", [
                                                                  _vm._v(
                                                                    "Untitled"
                                                                  ),
                                                                ]),
                                                            _vm._v(" "),
                                                            item.location
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "c-card-primary__footer-icon",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        staticClass:
                                                                          "c-icon c-icon--small u-color-primary",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "use",
                                                                          {
                                                                            attrs: {
                                                                              "xlink:href":
                                                                                "/assets/assets/img/spritemap.svg#sprite-ic-map",
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "small",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.location
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-button-arrow",
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "c-icon",
                                                              },
                                                              [
                                                                _c("use", {
                                                                  attrs: {
                                                                    "xlink:href":
                                                                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "c-button-bookmark",
                                              class: {
                                                "c-button-bookmark--close":
                                                  _vm.type == "bookmarks",
                                              },
                                              attrs: {
                                                "data-bookmark-content-type":
                                                  item.contentType,
                                                "data-bookmark-content-id":
                                                  item.contentCode,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeBookmark(
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "activities-event-card"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "c-card-primary c-card-primary--light",
                                            attrs: { href: item.url },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "c-card__inner" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-card-primary__body",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__pic",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: item.image,
                                                            alt: item.name,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__footer",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-card-primary__footer-text",
                                                          },
                                                          [
                                                            item.name
                                                              ? _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.name
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("p", [
                                                                  _vm._v(
                                                                    "Untitled"
                                                                  ),
                                                                ]),
                                                            _vm._v(" "),
                                                            item.location
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "c-card-primary__footer-icon",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        staticClass:
                                                                          "c-icon c-icon--small u-color-primary",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "use",
                                                                          {
                                                                            attrs: {
                                                                              "xlink:href":
                                                                                "/assets/assets/img/spritemap.svg#sprite-ic-map",
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "small",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.location
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            item.date.from ||
                                                            item.date.to
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "c-card-primary__footer-icon",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        staticClass:
                                                                          "c-icon c-icon--small u-color-primary",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "use",
                                                                          {
                                                                            attrs: {
                                                                              "xlink:href":
                                                                                "/assets/assets/img/spritemap.svg#sprite-ic-calendrier",
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    item.date.from.date.getTime() ==
                                                                    item.date.to.date.getTime()
                                                                      ? _c(
                                                                          "small",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                    " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "capitalizeFirstLetter"
                                                                                  )(
                                                                                    item
                                                                                      .date
                                                                                      .from
                                                                                      .full
                                                                                  )
                                                                                )
                                                                            ),
                                                                            typeof item.hour ==
                                                                              "string" &&
                                                                            item.hour !=
                                                                              ""
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      ", " +
                                                                                        _vm._s(
                                                                                          item.hour
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    item.date.from.date.getTime() !=
                                                                    item.date.to.date.getTime()
                                                                      ? _c(
                                                                          "small",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                    Du " +
                                                                                _vm._s(
                                                                                  item
                                                                                    .date
                                                                                    .from
                                                                                    .full
                                                                                ) +
                                                                                " au " +
                                                                                _vm._s(
                                                                                  item
                                                                                    .date
                                                                                    .to
                                                                                    .full
                                                                                )
                                                                            ),
                                                                            typeof item.hour ==
                                                                              "string" &&
                                                                            item.hour !=
                                                                              ""
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      ", " +
                                                                                        _vm._s(
                                                                                          item.hour
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-button-arrow",
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "c-icon",
                                                              },
                                                              [
                                                                _c("use", {
                                                                  attrs: {
                                                                    "xlink:href":
                                                                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.date.from ||
                                                      item.date.to,
                                                    expression:
                                                      "item.date.from || item.date.to",
                                                  },
                                                ],
                                                staticClass: "c-card__flag",
                                              },
                                              [
                                                item.date.from
                                                  ? _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "c-card__flag-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.date.from
                                                                .light.day
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "c-card__flag-subtitle",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.date.from
                                                                .light.month
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.date.from.date.getTime() !=
                                                item.date.to.date.getTime()
                                                  ? _c("hr")
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.date.from.date.getTime() !=
                                                item.date.to.date.getTime()
                                                  ? _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "c-card__flag-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.date.to.light
                                                                .day
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "c-card__flag-subtitle",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.date.to.light
                                                                .month
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "c-button-bookmark",
                                              class: {
                                                "c-button-bookmark--close":
                                                  _vm.type == "bookmarks",
                                              },
                                              attrs: {
                                                "data-bookmark-content-type":
                                                  item.contentType,
                                                "data-bookmark-content-id":
                                                  item.contentCode,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeBookmark(
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "blog-card" ||
                                    item.type === "cities-card" ||
                                    item.type === "services-card"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "c-card-primary c-card-primary--light",
                                            attrs: { href: item.url },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "c-card__inner" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-card-primary__body",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__pic",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: item.image,
                                                            alt: item.name,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__footer",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-card-primary__footer-text",
                                                          },
                                                          [
                                                            item.name
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.name
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    "Untitled"
                                                                  ),
                                                                ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-button-arrow",
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "c-icon",
                                                              },
                                                              [
                                                                _c("use", {
                                                                  attrs: {
                                                                    "xlink:href":
                                                                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "c-button-bookmark",
                                              class: {
                                                "c-button-bookmark--close":
                                                  _vm.type == "bookmarks",
                                              },
                                              attrs: {
                                                "data-bookmark-content-type":
                                                  item.contentType,
                                                "data-bookmark-content-id":
                                                  item.contentCode,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeBookmark(
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "attractions-card"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "c-card-primary c-card-primary--light",
                                            attrs: { href: item.url },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "c-card__inner" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-card-primary__body",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__pic",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: item.image,
                                                            alt: item.name,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "c-card-primary__footer",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-card-primary__footer-text",
                                                          },
                                                          [
                                                            item.name
                                                              ? _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.name
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("p", [
                                                                  _vm._v(
                                                                    "Untitled"
                                                                  ),
                                                                ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "c-card-primary__footer-text__icons",
                                                              },
                                                              [
                                                                item.days
                                                                  ? _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "c-card-primary__footer-icon",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "svg",
                                                                          {
                                                                            staticClass:
                                                                              "c-icon c-icon--small u-color-primary",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "use",
                                                                              {
                                                                                attrs: {
                                                                                  "xlink:href":
                                                                                    "/assets/assets/img/spritemap.svg#sprite-ic-calendrier",
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.days
                                                                              ) +
                                                                                " jours"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                item.cities
                                                                  ? _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "c-card-primary__footer-icon",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "svg",
                                                                          {
                                                                            staticClass:
                                                                              "c-icon c-icon--small u-color-primary",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "use",
                                                                              {
                                                                                attrs: {
                                                                                  "xlink:href":
                                                                                    "/assets/assets/img/spritemap.svg#sprite-ic-map",
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.cities
                                                                              ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "c-button-arrow",
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "c-icon",
                                                              },
                                                              [
                                                                _c("use", {
                                                                  attrs: {
                                                                    "xlink:href":
                                                                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "c-button-bookmark",
                                              class: {
                                                "c-button-bookmark--close":
                                                  _vm.type == "bookmarks",
                                              },
                                              attrs: {
                                                "data-bookmark-content-type":
                                                  item.contentType,
                                                "data-bookmark-content-id":
                                                  item.contentCode,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeBookmark(
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.searchResults.length == 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "o-layout__item u-12/12 u-text-center",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v("Aucun résultat à afficher"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "c-button u-margin-top-large",
                            attrs: { href: "#", "data-step": 0 },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.start($event)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("Recommencer")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "c-button__icon" }, [
                              _c("svg", { staticClass: "c-icon" }, [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite",
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }