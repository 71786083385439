<template>
    <div class="c-dropdown" :data-type="filterarg">
        <slot></slot>
        <v-select :options="options" v-model="selectedOption" :reduce="(option) => option.id" :searchable="false" @input="onChange"></v-select>
    </div>
</template>

<script>
import VSelect from 'vue-select'
import OpenIndicator from './OpenIndicator.vue'
import 'vue-select/dist/vue-select.css'
// Docs :  https://vue-select.org/
VSelect.props.components.default = () => ({ OpenIndicator })

export default {
    data () {
        return {
            options: [],
            selectedOption: {label: 'Sélectionner', id: 'null', noOptions: 'Désolé, aucune option correspondante.'}
        }
    },
    props: {

        filterarg: "",
        /**
         * Populate options from array
         */
        propsOptions: {
            type: Array
        },

        /*
         * Select first option by default
         */
        firstSelected: {
            type: Boolean,
            default: false
        }
    },
    beforeMount() {
        if ( this.propsOptions ) {
            this.propsOptions.forEach((item, index) => {
                this.options.push({label: item.name, id: item.id });
            });
        }
    },
    mounted() {
        let selectedIndex

        Array.from(this.$el.querySelectorAll('select option')).forEach((item, index) => {
            if(index == 0) {
                this.selectedOption = {label: item.text, id: 'null'}
            }else{
                this.options.push({label: item.text, id: item.getAttribute('value')})

                if(item.selected) {
                    this.selectedOption = this.options[index-1]
                }
            }
        })
    },
    methods: {
        onChange(value) {
            this.$emit('change', this.selectedOption)
        },
        getData() {
            return this.selectedOption
        },
        update(datas) {
            datas.forEach((item, index) => {
                this.options.push({label: item.name + " " + "(" + item.content.length + ")", id: index });
            })
        }
    },
    components: {
        VSelect,
        OpenIndicator
    }
}
</script>

<style lang="scss">
    :root {
        --vs-dropdown-option--active-bg: var(--global-color-brand);
    }

    .c-dropdown {

        select {
            display: none;
        }

        .vs__dropdown-toggle {
            padding: 10px 12px;
            border-radius: 0;
            border: none;
            background: var(--global-color-light-blue);
            border-bottom: solid 3px var(--global-color-teal-blue);
        }

        .vs__selected {
            color: var(--global-color-brand);
        }

        .vs__dropdown-menu {
            background: var(--global-color-brand);
            border-radius: 0;
            color: white;
            padding-bottom: 0;
            padding-top: 0;
            border: none;

            .vs__dropdown-option {
                padding-top: 10px;
                padding-bottom: 10px;

                &:hover, &:focus {
                    background: var(--global-color-teal-blue);
                }
            }
        }

        .vs__clear {
            display: none;
        }

        .vs__open-indicator {
            width: 12px;
            height: auto;
            fill: var(--global-color-brand);
        }
    }
</style>
