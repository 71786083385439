import Component from '../classes/Component'
import * as dat from 'dat.gui'

let $html
let settings
const DEFAULT_THEME = 'spring'

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        this.$html = document.documentElement;

        this.initTheme ()

        let gui = new dat.GUI();
        let themeOptions = gui.add(this.settings, 'options', [ 'spring', 'summer', 'autumn', 'winter' ] ).name('Theme').listen()

        let displayBar = { add: function(){ this.displayBar() }.bind(this) }
        gui.add(displayBar, 'add').name('Display Top Bar')

        themeOptions.onChange(
            function(newValue) {
                this.switchTheme(newValue)
            }.bind(this)
        )

        gui.close()

        document.querySelector('.dg.a').style.marginRight = '125px';
        document.querySelector('.dg').style.zIndex = 99999;
    }

    initTheme () {
        if(localStorage.getItem('theme')) {
            this.settings = {
                options: localStorage.getItem('theme')
            }

            this.$html.dataset.theme = localStorage.getItem('theme')
        }else{
            localStorage.setItem('theme', DEFAULT_THEME)

            this.$html.setAttribute('data-theme', DEFAULT_THEME)

            this.settings = {
                options: DEFAULT_THEME
            }
        }
    }

    switchTheme (val) {
        localStorage.setItem('theme', val)
        this.$html.setAttribute('data-theme', val)
    }

    displayBar () {
        if(sessionStorage.getItem('announcement')) {
            sessionStorage.removeItem('announcement')
            document.querySelector('[data-announcement-bar]').classList.add('is-active')
        }
    }
}
