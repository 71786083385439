import AutoBind from 'auto-bind'

export default class PrintPage {

    constructor (element) {
        AutoBind(this)

        this.init()
    }

    init() {
        Array.from(document.querySelectorAll('[data-print-page]')).forEach((item, index) => {
            item.addEventListener('click', ev => {
                ev.preventDefault()
                this.print()
            })
        })
    }

    print() {
        window.print()
    }
}
