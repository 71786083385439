var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-search-result" },
    [
      this.total != 0
        ? _c("h1", [_vm._v("Résultats de recherche")])
        : _c("h1", [_vm._v("Aucun résultat")]),
      _vm._v(" "),
      _c("h3", { staticClass: "u-color-teal-blue" }, [
        _vm._v(
          _vm._s(this.total) +
            " résultats de recherche pour « " +
            _vm._s(this.searchFilter) +
            " »"
        ),
      ]),
      _vm._v(" "),
      _c("app-tab-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.total != 0,
            expression: "this.total != 0",
          },
        ],
        ref: "tablist",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }