<template>
    <div
        class="c-datepicker"
        :class="{ 'c-datepicker--translate-up': isTranslated }"
    >
        <VueDatePicker
            inline
            range
            v-model="date"
            :locale="locale"
            :color="color"
            :min-date="minDate"
            :max-date="maxDate"
        />
    </div>
</template>

<script>
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import dayjs from "dayjs";
let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default {
    props: ["isTranslated"],
    data() {
        let today = new Date();
        let maxDate = new Date(today.getFullYear(), today.getMonth() + 12, today.getDate());
        let endDate = new Date(today.getFullYear(), today.getMonth() , today.getDate() + 7);

        return {
            minDate: today,
            maxDate: maxDate,
            date: {
                start: today,
                end: endDate,
            },
            // allowedDates: date => { this.isAllowedDate(date) },
            locale: { lang: document.documentElement.lang },
            color: getComputedStyle(document.documentElement)
                .getPropertyValue("--global-color-teal-blue")
                .trim(),
        };
    },
    mounted() {
        this.selectedDateUpdated(this.date);
        let foo = "2022-04-14";
        //console.log(parseInt(foo.split('-')[2], 10) % 2 === 0)
    },
    methods: {
        selectedDateUpdated(dates) {
            if (Object.keys(dates).length > 0) {
                this.$emit("date-picker-updated", {
                    start: dayjs(dates.start).format("YYYY-MM-DD"),
                    end: dayjs(dates.end).format("YYYY-MM-DD"),
                });
            }
        },
        // isAllowedDate (date) {
        //     return parseInt(date.split('-')[2], 10) % 2 === 0;
        // }
    },
    components: {
        VueDatePicker,
    },
    watch: {
        date: function (val) {
            this.selectedDateUpdated(val);
        },
    },
};

</script>

<style lang="scss"></style>
