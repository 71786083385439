import Component from '../classes/Component'
import Swiper, { Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import "swiper/css/pagination";

let BREAKPOINT_TABLET
let BREAKPOINT_DESKTOP
let BREAKPOINT_WIDE
let BREAKPOINT_HUGE
let BREAKPOINT_ENORMOUS

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init () {
        Swiper.use([Pagination, Autoplay])
        var that = this;

        this.BREAKPOINT_TABLET = getComputedStyle(document.documentElement).getPropertyValue('--global-bp-tablet').trim().replace('px', '')

        this.BREAKPOINT_DESKTOP = getComputedStyle(document.documentElement).getPropertyValue('--global-bp-desktop').trim().replace('px', '')

        this.BREAKPOINT_WIDE = getComputedStyle(document.documentElement).getPropertyValue('--global-bp-wide').trim().replace('px', '')

        this.BREAKPOINT_HUGE = getComputedStyle(document.documentElement).getPropertyValue('--global-bp-huge').trim().replace('px', '')

        this.BREAKPOINT_ENORMOUS = getComputedStyle(document.documentElement).getPropertyValue('--global-bp-enormous').trim().replace('px', '')

        let slidesCount = Array.from(this.element.querySelectorAll('.swiper-slide:not([style*="display:none"]):not([style*="display: none"])')).length;

        let swiper = new Swiper(this.element.querySelector('.swiper'), {
            speed: 1000,
            autoplay: {
                delay: 3000
            },
            loop: slidesCount > 1,
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: false,
            watchOverflow: true,
            slidesPerGroup: 1,
            spaceBetween: 10,
            pagination: {
                el: this.element.querySelector('.swiper-pagination'),
                type: 'fraction'
            },
            breakpoints: {
                [this.BREAKPOINT_TABLET]: {

                    slidesPerView: 2,
                    spaceBetween: 20,
                    centeredSlides: false,
                    pagination: {
                        type: 'bullets',
                        clickable: true
                    }
                },
                [this.BREAKPOINT_HUGE]: {

                    slidesPerView: 3,
                    spaceBetween: 20,
                    centeredSlides: false,
                    pagination: {
                        type: 'bullets',
                        clickable: true
                    }
                }
            }
        })

        Document.prototype.reloadSwipper = function () {
            swiper.update();
            swiper.updateSlides();

        }
    }
}
