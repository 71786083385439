import Component from '../classes/Component'
import Swiper, { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

let map
let carousel
let BREAKPOINT
let swiper
let slidesIDs

export default class extends Component {

    constructor (element, args) {
        super(element, args)
    }

    init() {
        this.map = this.element.querySelector('[data-svg-map] svg')

        this.carousel = this.element.querySelector('[data-carousel]')

        this.BREAKPOINT = getComputedStyle(document.documentElement).getPropertyValue('--global-bp-desktop').trim().replace('px', '')

        this.slidesIDs = []
        Array.from(this.element.querySelectorAll('[data-region]')).forEach((item, index) => {
            this.slidesIDs.push(item.dataset.region)
        })

        Swiper.use([Pagination])

        // let slidesCount = Array.from(this.element.querySelectorAll('.swiper-slide')).length;

        this.swiper = new Swiper(this.element.querySelector('.swiper'), {
            speed: 1000,
            slidesPerView: 'auto',
            centeredSlides: true,
            watchOverflow: true,
            slidesPerGroup: 1,
            spaceBetween: 10,
            pagination: {
                el: this.element.querySelector('.swiper-pagination'),
                type: 'bullets',
            },
            breakpoints: {
                [this.BREAKPOINT]: {
                    speed: 1,
                    spaceBetween: 0,
                    allowTouchMove: false
                }
            }
        });

        this.handleMapEvents()

    }

    handleMapEvents () {
        Array.from(this.map.querySelectorAll('.c-interactive-map__region')).forEach((item, index) => {
            item.addEventListener('click', ev => {
                ev.stopPropagation()

                this.slidesIDs.forEach((item, index) => {
                    if(item == ev.target.id) {
                        this.swiper.slideTo(index)
                    }
                })

                this.carousel.classList.add('is-active')
            })

            // Close map if click outside
            document.addEventListener('click', function (event) {
                if (!event.target.closest('[data-carousel]')) {
                    this.carousel.classList.remove('is-active')
                }
            }.bind(this), false)
        })
    }
}
