<template>
    <div class="o-container">
        <div class="o-layout">
            <div class="o-layout__item u-12/12 u-8/12@desktop o-layout__item--order-2@desktop">
                <slot></slot>
            </div>
            <div class="o-layout__item u-12/12 u-4/12@desktop o-layout__item--order-1@desktop">
                <app-date-picker :is-translated="true" v-on:date-picker-updated="setDateRange" min></app-date-picker>
            </div>
        </div>
        <div class="o-layout u-padding-top-large u-padding-top-huge@desktop">
            <div class="o-layout__item u-12/12">
                <h2 class="u-h2 u-margin-bottom-tiny u-margin-bottom@desktop">{{ selectedDates }}</h2>
            </div>
        </div>
        <div class="o-layout u-padding-top">
            <div v-for="(item, index) in searchResults.slice(0, itemsPerPage)" :key="index" class="o-layout__item u-margin-bottom u-12/12 u-6/12@tablet u-4/12@huge">
                <a v-if="item.type === 'activities-event-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                    <div class="c-card__inner">
                        <div class="c-card-primary__body">
                            <div class="c-card-primary__pic">
                                <img :src="item.image" :alt="item.name">
                            </div>
                            <div class="c-card-primary__footer">
                                <div class="c-card-primary__footer-text">
                                    <p v-if="item.name">{{ item.name }}</p>
                                    <p v-else>Untitled</p>
                                    <p v-if="item.location" class="c-card-primary__footer-icon">
                                        <svg class="c-icon c-icon--small u-color-primary">
                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                        </svg>
                                        <small>{{ item.location }}</small>
                                    </p>
                                    <p v-if="item.date.from || item.date.to" class="c-card-primary__footer-icon">
                                        <svg class="c-icon c-icon--small u-color-primary">
                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-calendrier"></use>
                                        </svg>
                                        <small v-if="item.date.from.date.getTime() == item.date.to.date.getTime()">
                                            {{ item.date.from.full | capitalizeFirstLetter  }}<span v-if="typeof item.hour == 'string' && item.hour != ''">, {{ item.hour }}</span>
                                        </small>
                                        <small v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                                            Du {{ item.date.from.full }} au {{ item.date.to.full }}<span v-if="typeof item.hour == 'string' && item.hour != ''">, {{ item.hour }}</span>
                                        </small>
                                    </p>
                                </div>
                                <div class="c-button-arrow">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="item.date.from || item.date.to" class="c-card__flag">
                        <div v-if="item.date.from">
                            <span class="c-card__flag-title">{{ item.date.from.light.day }}</span>
                            <span class="c-card__flag-subtitle">{{ item.date.from.light.month }}</span>
                        </div>
                        <hr v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                        <div v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                            <span class="c-card__flag-title">{{ item.date.to.light.day }}</span>
                            <span class="c-card__flag-subtitle">{{ item.date.to.light.month }}</span>
                        </div>
                    </div>
                    <div class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                </a>
            </div>
            <!-- Message if no result -->
            <div v-if="searchResults.length == 0" class="o-layout__item u-12/12 u-text-center">
                <p>Il n’y a aucun activité ou événement pour la date ou les dates sélectionnées. Sélectionnez d'autres dates.</p> <!-- TODO : Text message if no result -->
            </div>
        </div>
        <div v-if="pageCount > 1" class="o-layout u-padding-top">
            <div class="o-layout__item u-12/12 u-text-center">
                <div class="c-pagination">
                    <a href="#" @click="toPrevTab" :data-index="1" class="c-pagination__prev" :class="{'is-disabled': activePage == 1}">
                        <svg class="c-icon">
                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-gauche"></use>
                        </svg>
                    </a>
                    <ul class="c-pagination__numbers o-list-inline u-margin-none">
                        <li v-for="index in pageCount" :key="index" class="o-list-inline__item">
                            <a href="#" :class="{'is-active': index == activePage}" @click="changePagination"><span :data-index="index">{{ index }}</span></a>
                        </li>
                    </ul>
                    <a href="#" @click="toNextTab" :data-index="pageCount" class="c-pagination__next" :class="{'is-disabled': activePage == pageCount}">
                        <svg class="c-icon">
                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from './DatePicker.vue'
import dayjs from 'dayjs'
let localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export default {
    data () {
        return {
            selectedDates: '',
            dateRange : null ,
            searchResults: [],
            pageCount: 0,
            activePage: 1,
            itemsPerPage: 21
        }
    },
    filters : {
        capitalizeFirstLetter : function(value){
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
    updated() {
        // Trigger Event to handle Bookmarks
        this.$root.$emit('searchResultsUpdate')
    },
    methods: {
        setDateRange (ev) {
            this.selectedDates = 'Du ' + dayjs(ev.start).format('LL') + ' au ' + dayjs(ev.end).format('LL');
            this.dateRange = ev;
            this.research(this.dateRange ,1 , this.pageSize)
        },
        searchSuccess(data) {
            if(!window.transformeSearchResult) {
                return
            }
            let transformedSearchResult = window.transformeSearchResult(data)
            if(transformedSearchResult != null) {
                this.searchResults = transformedSearchResult.items[1].content
                this.activePage = transformedSearchResult.items[1].activePage
                this.pageCount = transformedSearchResult.items[1].pageCount
                this.itemsPerPage = transformedSearchResult.items[1].itemsPerPage
            } else {
                this.searchResults = []
            }
        },
        searchError(data) {
            alert('Erreur dans la recherche, veuillez recommencer.')
        },
        research(ev, pageIndex, itemPerPage) {
            window.research(new Date(ev.start.replace(/-/g, '\/')), new Date(ev.end.replace(/-/g, '\/')), this.searchSuccess, this.searchError, pageIndex || 0, itemPerPage || 21)
        },
        changePagination(ev) {
            let index = ev.target.dataset.index;
            this.research(this.dateRange,index, this.itemsPerPage);
        },
        toPrevTab(ev) {            
            let index = ev.target.dataset.index;
            this.research(this.dateRange,index, this.itemsPerPage);
        },
        toNextTab(ev) {
            let index = ev.target.dataset.index
            this.research(this.dateRange,index, this.itemsPerPage);
        }
    },
    components: {
        'app-date-picker': DatePicker
    }
}
</script>

<style lang="scss">

</style>
