<template>
    <section class="c-quiz u-padding-vertical-large" :class="{ 'u-bgcolor-teal-blue': currentStepNumber > 0 }">
        <div class="o-container">
            <div v-if="currentStepNumber > 0" class="o-layout u-margin-bottom-large">
                <div class="o-layout__item u-text-center u-12/12">
                    <div class="c-quiz__stepper">
                        <div v-for="(step, index) in steps" :key="index"
                            class="c-quiz__stepper-item" 
                            :class="{ 'is-active': currentStepNumber == step.number }">
                            <span>{{ step.title }}</span>
                            <a href="#" @click.prevent="goToStepHandler" :data-step="step.number">{{ step.number }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-layout">
                <div v-if="currentStepNumber == 0" class="o-layout__item u-12/12">   
                    <slot v-bind:currentStepNumber="0" v-bind:start="start"></slot>
                </div>                
                <div v-if="currentStepNumber > 0" class="o-layout__item u-12/12"> 
                    <div v-if="!currentStep.isFinal && currentStepQuestion" class="c-quiz__step" :data-step="currentStep.number">
                            
                            <h2 class="u-h2 u-color-white">{{ currentStepQuestion.title | get_value }}</h2>

                            <div v-if="!currentStepQuestion.isSingle" class="c-quiz__field-group">
                                <template v-for="(answer, answerIndex) in currentStepAnswers" >
                                    <div :key="answerIndex" v-if="answer.code != 'Default'" class="c-quiz__item">
                                        <input type="checkbox" :id="answer.code" :name="currentStep.code" :value="answer.id" :data-code="answer.code" :data-season="currentStepQuestion.useSeason" @change="selectedCriteria($event)">
                                        <label :for="answer.code" class="c-quiz__item-label"><span>{{ answer.title }}</span></label>
                                    </div>
                                </template>
                            </div>

                            <div v-if="currentStepQuestion.isSingle" class="c-quiz__field-group">
                                <template v-for="(answer, answerIndex) in currentStepAnswers" >
                                    <div :key="answerIndex" v-if="answer.code != 'Default'" class="c-quiz__item">
                                        <input type="radio" :id="answer.code" :name="currentStep.code" :value="answer.id" :data-code="answer.code" :data-season="currentStepQuestion.useSeason" @change="selectedCriteria($event)">
                                        <label :for="answer.code" class="c-quiz__item-label"><span>{{ answer.title }}</span></label>
                                    </div>
                                </template>
                            </div>

                            <a href="#" class="c-button u-margin-top-large" @click.prevent="next" :data-step="currentStep.number">
                                <span>Suivant</span>
                                <div class="c-button__icon">
                                    <svg class="c-icon">
                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                    </svg>
                                </div>
                            </a>
                    </div>

                    <div v-if="currentStep.isFinal" class="c-quiz__step" :data-step="currentStep.number">
                        <h2 class="u-h2 u-color-white u-margin-bottom-large">Vos suggestions d'escapades</h2>
                        <div class="o-layout">
                            <div v-for="(item, itemIdex) in searchResults" :key="itemIdex"  class="o-layout__item u-margin-bottom u-12/12 u-6/12@tablet u-4/12@huge">
                                <a v-if="item.type === 'adventure-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner" >
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <p v-if="item.name">{{ item.name }}</p>
                                                <p v-else>Untitled</p>
                                                <p v-if="item.location" class="c-card-primary__footer-icon">
                                                    <svg class="c-icon c-icon--small u-color-primary">
                                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                                    </svg>
                                                    <small>{{ item.location }}</small>
                                                </p>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)" class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                            <a v-if="item.type === 'activities-event-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner">
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <p v-if="item.name">{{ item.name }}</p>
                                                <p v-else>Untitled</p>
                                                <p v-if="item.location" class="c-card-primary__footer-icon">
                                                    <svg class="c-icon c-icon--small u-color-primary">
                                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                                    </svg>
                                                    <small>{{ item.location }}</small>
                                                </p>
                                                <p v-if="item.date.from || item.date.to" class="c-card-primary__footer-icon">
                                                    <svg class="c-icon c-icon--small u-color-primary">
                                                        <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-calendrier"></use>
                                                    </svg>
                                                    <small v-if="item.date.from.date.getTime() == item.date.to.date.getTime()">
                                                        {{ item.date.from.full | capitalizeFirstLetter }}<span v-if="typeof item.hour == 'string' && item.hour != ''">, {{ item.hour }}</span>
                                                    </small>
                                                    <small v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                                                        Du {{ item.date.from.full }} au {{ item.date.to.full }}<span v-if="typeof item.hour == 'string' && item.hour != ''">, {{ item.hour }}</span>
                                                    </small>
                                                </p>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="item.date.from || item.date.to" class="c-card__flag">
                                    <div v-if="item.date.from">
                                        <span class="c-card__flag-title">{{ item.date.from.light.day }}</span>
                                        <span class="c-card__flag-subtitle">{{ item.date.from.light.month }}</span>
                                    </div>
                                    <hr v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                                    <div v-if="item.date.from.date.getTime() != item.date.to.date.getTime()">
                                        <span class="c-card__flag-title">{{ item.date.to.light.day }}</span>
                                        <span class="c-card__flag-subtitle">{{ item.date.to.light.month }}</span>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)"  class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                            <a v-if="item.type === 'blog-card' || item.type === 'cities-card' || item.type === 'services-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner">
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <span v-if="item.name">{{ item.name }}</span>
                                                <span v-else>Untitled</span>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)" class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                            <a v-if="item.type === 'attractions-card'" :href="item.url" class="c-card-primary c-card-primary--light">
                                <div class="c-card__inner">
                                    <div class="c-card-primary__body">
                                        <div class="c-card-primary__pic">
                                            <img :src="item.image" :alt="item.name">
                                        </div>
                                        <div class="c-card-primary__footer">
                                            <div class="c-card-primary__footer-text">
                                                <p v-if="item.name">{{ item.name }}</p>
                                                <p v-else>Untitled</p>
                                                <div class="c-card-primary__footer-text__icons">
                                                    <p v-if="item.days" class="c-card-primary__footer-icon">
                                                        <svg class="c-icon c-icon--small u-color-primary">
                                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-calendrier"></use>
                                                        </svg>
                                                        <small>{{ item.days }} jours</small>
                                                    </p>
                                                    <p v-if="item.cities" class="c-card-primary__footer-icon">
                                                        <svg class="c-icon c-icon--small u-color-primary">
                                                            <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-map"></use>
                                                        </svg>
                                                        <small>{{ item.cities }} </small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="c-button-arrow">
                                                <svg class="c-icon">
                                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div @click="removeBookmark($event)" class="c-button-bookmark" :class="{'c-button-bookmark--close': type == 'bookmarks'}" :data-bookmark-content-type="item.contentType" :data-bookmark-content-id="item.contentCode"></div>
                            </a>
                            </div>
                            
                            <!-- Message if no result -->
                            <div v-if="searchResults.length == 0" class="o-layout__item u-12/12 u-text-center">
                                <p>Aucun résultat à afficher</p> <!-- TODO : Text message if no result -->
                            </div>
                        </div>
                        <a href="#" @click.prevent="start" :data-step="0" class="c-button u-margin-top-large">
                            <span>Recommencer</span>
                            <div class="c-button__icon">
                                <svg class="c-icon">
                                    <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!--<div v-show="pageCount > 1" class="o-layout u-padding-top pagination-tab">
                <div class="o-layout__item u-12/12 u-text-center">
                    <div class="c-pagination">
                        <a href="#" @click="toPrevTab" :data-index="1" class="c-pagination__prev" :class="{'is-disabled': activePage == 1}">
                            <svg class="c-icon">
                                <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-gauche"></use>
                            </svg>
                        </a>
                        <ul class="c-pagination__numbers o-list-inline u-margin-none">
                            <li v-for="index in pageCount" :key="index" class="o-list-inline__item">
                                <a href="#" :class="{'is-active': index == activePage}" @click="changePagination" ><span :data-index="index">{{ index }}</span></a>
                            </li>
                        </ul>
                        <a href="#" @click="toNextTab" :data-index="pageCount" class="c-pagination__next" :class="{'is-disabled': activePage == pageCount}">
                            <svg class="c-icon">
                                <use xlink:href="/assets/assets/img/spritemap.svg#sprite-ic-fleche-droite"></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>-->
        </div>
    </section>
</template>

<script>

export default {
    data() {
        return {            
            steps: [],
            questions: [],
            answers: [],
            seasons : [], 
            currentStep: null,
            searchResults : [],            
            itemsPerPage : 21,
            pageIndex : 1,
            activePage : 0,
            pageCount : 0,
            currentStepNumber: 0,
            stepQuestionAnswers :[],
            currentStepQuestion : null,
            currentStepAnswers : null,
            selectedCriterias : []
        }
    },
    filters : {
        capitalizeFirstLetter : function(value){
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
    mounted() {
       
       window.seasons.forEach((season)=>{
            this.seasons.push({
                title : window.getData(season.Name),
                code : window.getData(season.code),
                id : window.getData(season._content_id),
            });
        });

        window.steps.forEach((step)=>{
            let isFinal = window.getData(step.Isfinalstep);
            if(this.isObject(isFinal)){
                isFinal = isFinal.iv;
            }
            this.steps.push({
                title : window.getData(step.Name),
                number : parseInt(window.getData(step.Number)),
                isFinal : isFinal,
                code : window.getData(step.code),
                id : window.getData(step._content_id)
            });
        });

        window.questions.forEach((question)=>{
            let useSeason = window.getData(question.Useseasonasanswer);
            if(this.isObject(useSeason)){
                useSeason = useSeason.iv;
            }
            this.questions.push({
                title : window.getData(question.Question),
                useSeason : useSeason,
                step : window.getData(question.Step)[0],
                stepNumber : parseInt(this.steps.find(v=>v.id == window.getData(question.Step)).number),
                code : window.getData(question.code),
                id : window.getData(question._content_id),
                isSingle  : window.getData(question.SingleChoice),
            });
        });

        window.answers.forEach((answer)=>{
            this.answers.push({
                question : window.getData(answer.Escapadequestion)[0],
                title : window.getData(answer.Answer),
                code : window.getData(answer.code),
                id : window.getData(answer._content_id)
            });
        });
    },
    methods: {
        isObject(val) {
            if (val === null) { return false;}
            return ( (typeof val === 'function') || (typeof val === 'object') );
        },
        start(ev){   
            this.currentStepNumber = 0;
            this.searchResults = []; 
            this.selectedCriterias = [];     
            this.getStepData(1);
        },
        next(ev){    
            if(this.steps.length <= this.currentStepNumber){
                this.currentStepNumber = this.steps.length;
            }else{
                this.currentStepNumber++;
            }
            this.getStepData(this.currentStepNumber);
        },
        goToStepHandler(ev){
            let step = parseInt(ev.target.dataset.step);
            this.getStepData(step);
        },
        getStepData(stepNumber){
            try {               
                this.currentStepNumber = parseInt(stepNumber);            
                this.currentStep = this.steps.find(v=>v.number == this.currentStepNumber);
            
                if(this.currentStep && this.currentStep.isFinal){
                    this.research(this.selectedCriterias, this.pageIndex, this.itemsPerPage);
                    return;
                }

                this.currentStepQuestion = this.questions.find(v=>v.step == this.currentStep.id);

                if(this.currentStepQuestion){
                    if(this.currentStepQuestion.useSeason){
                        this.currentStepAnswers = this.seasons;
                    }else{
                        this.currentStepAnswers = this.answers.filter(v => v.question == this.currentStepQuestion.id);
                    }
                }
            }
            catch(err) {
                console.log(err);
            }
        },
        selectedCriteria(e){            
            let id = e.target.value;
            let stepCode = e.target.name;
            let answerCode = e.target.dataset.code;
            let useSeason = e.target.dataset.season;
            let isCheck = e.target.checked;

            let item = this.selectedCriterias.find(v=>v.answerCode == answerCode);

            if(item){
                this.selectedCriterias = this.selectedCriterias.filter(v=>v.answerCode != answerCode);
                if(!isCheck){
                    return;
                }
            }

            this.selectedCriterias.push({
                useSeason : useSeason,
                id : id,
                stepCode : stepCode,
                answerCode : answerCode
            }); 
        },
        searchSuccess(data) {
            if(!window.transformeSearchResult) {
                return
            }
            let transformedSearchResult = window.transformeSearchResult(data)
            if(transformedSearchResult != null) {
                this.searchResults = transformedSearchResult.items[1].content
                this.activePage = transformedSearchResult.items[1].activePage
                this.pageCount = transformedSearchResult.items[1].pageCount
                this.itemsPerPage = transformedSearchResult.items[1].itemsPerPage
            } else {
                this.searchResults = []
            }
        },
        searchError(data) {
            alert('Erreur dans la recherche, veuillez recommencer.');
        },
        research(criteria, pageIndex, itemPerPage) {
            window.research(criteria, this.searchSuccess, this.searchError, pageIndex || 0, itemPerPage || this.itemsPerPage)
        },        
        changePagination(ev) {
            let index = ev.target.dataset.index;
            this.research(this.selectedCriterias, index, this.itemPerPage);
        },
        toPrevTab(ev) {
            let index = ev.target.dataset.index
           this.research(this.selectedCriterias, index, this.itemPerPage);
        },
        toNextTab(ev) {
            let index = ev.target.dataset.index
            this.research(this.selectedCriterias, index, this.itemPerPage);
        },
        reset() {            
            this.getStepData(1);
            this.scrollTopSection();
        },
        scrollTopSection() {
            window.scrollTo({ top: this.$el.offsetTop - document.querySelector('.c-header').offsetHeight - (document.querySelector('.c-header').offsetTop - document.querySelector('.c-header').offsetHeight), behavior: 'smooth' })
        }
    }
}
</script>

<style lang="scss">
</style>
